<template>
  <div class="download-page">
    <div class="center">
      <img class="dowload-img" src="@/assets/image/cloud-download-outline.svg" alt="download-icon">
      <div class="download-msg">Downloading Statement</div>
      <a href="" id="download-report" :download="fileName" style="display: none"></a>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DownloadStatement",
  beforeMount() {
    let isDownloadHash = this.$route.query['id'];
    if (isDownloadHash) {
      this.downloadStatement(isDownloadHash);
    }
  },
  computed: {
    fileName() {
      //FIXME smarter name
      return 'Statement-' + moment().format('DD-MM-yyyy') + '.pdf';
    }
  },
  methods: {
    async downloadStatement(hash) {
      hash = encodeURIComponent(hash);

      this.$feedback.showLoading()

      try {
        let {data, headers} = await this.$cppClient.downloadWithoutAuth('statement/download', {id: hash});

        this.$feedback.hideLoading();

        let blob = new Blob([data]);
        let url = URL.createObjectURL(blob);
        let anchorTag = document.getElementById('download-report');
        anchorTag.download = headers["x-recommended-name"] || this.fileName;
        anchorTag.href = url;
        anchorTag.click();
      } catch (ex) {
        this.$router.push({name: 'error'});
      } finally {
        this.$feedback.hideLoading();
      }
    }
  }
}
</script>

<style scoped>
.download-page {
  height: 100vh;
}

.center {
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.download-msg {
  color: #e2136e;
  text-align: center;
  font-size: 34px;
  font-weight: 500;
  font-family: Roboto sans-serif;
}

@media only screen and (max-width: 600px) {
  .download-img {
    width: 250px;
  }

  .download-msg {
    font-size: 14px;
  }
}

@media only screen and (min-width: 600px) {
  .dowload-img {
    width: 200px;
  }

  .download-msg {
    font-size: 24px;
  }
}
</style>